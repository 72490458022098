import React,{useRef,useEffect} from "react";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { FormGroup, Row, Col,Button } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Formulario(props) {
    const inputRef=useRef(null);

    useEffect(()=>{
        inputRef.current.focus();
    },[])
    const formSchema = Yup.object().shape({

        UserName: Yup.string()
            .min(4, `Mínimo 5 caracteres`)
            .max(55, `Máximo 55 caracteres`)
            .required("Campo Requerido"),
        Password: Yup.string()
            .required("Campo Requerido")
            .min(5, `Mínimo 5 caracteres`),

    });

    return (
        <>
            <Formik initialValues={
                {
                    UserName: "",
                    Password: "",

                }
            }
                validationSchema={formSchema}
                onSubmit={
                    (values) => { props.valores(values) }} >
                
                <Form >
                    <FormGroup >
                        <label htmlFor='UserName' >Usuario: </label>
                        <Field className='form-control'
                           innerRef={inputRef}
                            name='UserName'
                            placeholder=''
                            type='text' />
                        <ErrorMessage name='UserName'
                            component='div'
                            className='field-error text-danger' />
                    </FormGroup>

                    <FormGroup className="form-pass">
                        <label htmlFor='Password' >
                            Contraseña:
                        </label>
                        <Field className='form-control'
                            name='Password'
                            placeholder=''
                            type='password' />
                        <Link to="/nuevopassword" > ¿Olvidaste tu contraseña ? </Link>
                        <ErrorMessage name='Password'
                            component='div'
                            className='field-error text-danger' />
                    </FormGroup>

                    <Row >
                        <Col lg={12} md={12} > 
                           
                            <div className="d-grid gap-2">
                                <Button
                                    type="submit"
                                   
                                    disabled={props.loading}
                                >

                                    {props.loading ? ("Esperar") : ("Ingresar")}
                                </Button>
                            </div>

                        </Col>
                    </Row>
                </Form>
            </Formik>
        </>
    );
}