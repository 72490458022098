
import { Table, Button } from "react-bootstrap";
import { RiDeleteBack2Line } from 'react-icons/ri';
import cflService from "../service/cfl-service";
import Swal from 'sweetalert2'
const TablaAlumnosxCurso = ({ alumnoxCurso, setAlumnosxCurso, filacurso }) => {

    const deleteRow = (data) => {
        Swal.fire({
            title: '¿Esta seguro?',
            text: "Esta accion no tiene vuelta atrás",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, borrar!',
            cancelButtonText:"Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                setAlumnosxCurso(alumnoxCurso.filter(item => item.idAlumnos != data.idAlumnos))
                Swal.fire(
                    'Borrado!',
                    `El alumno ${data.nombre }  ${data.apellido} ya no esta matriculado `,
                    'success'
                )
                cflService.deleteMatricular(filacurso.idCurso, data.idAlumnos)
                    .then((resp) => {
                       console.log("borrardo")
                    })
                    .catch((error) => console.log(error))

            }
        })


    }

    return (
        <Table striped bordered hover className="mt-3" responsive>
            <thead>
                <tr>
                    <th>DNI</th>
                    <th>Nombres</th>
                    <th>Apellidos</th>
                    <th>Email</th>
                    <th>Accion</th>
                </tr>
            </thead>
            <tbody>

                {alumnoxCurso.map((data, i) => {
                    return (
                        <tr key={i}>
                            <td>{data.dni}</td>
                            <td>{data.nombre}</td>
                            <td>{data.apellido}</td>
                            <td>{data.email}</td>
                            <td>
                                <div className="center-accion">
                                    <Button variant="outline-danger" onClick={() => deleteRow(data)}>
                                        <RiDeleteBack2Line />
                                    </Button>
                                </div>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    )
}
export default TablaAlumnosxCurso;