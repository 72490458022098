import React from 'react';
import FormAlumno from '../components/FormAlumno';

const initialValues = {
    nombre: "",
    apellido: "",
    dni: "",
    sexo: '',
    fechaNacimiento: '',
    nacionalidad: "",
    lugar_nacimiento: "",
    telefono: "",
    calle: "",
    numero: "",
    localidad: "",
    partido: "",
    email: "",
    provincia: null,
    municipio: null,
    localidad: null,
    nacionalidad: null
}
const CrearAlumno=()=>{
    return(
        <>
        <h1>Crear Alumno</h1>
        <FormAlumno initialValues={initialValues} />
        </>
    )
}
export default  CrearAlumno