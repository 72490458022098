import React, { useState, useRef, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { FormGroup, Button, Row, Col, Modal } from "react-bootstrap";


export default function Formulario(props) {
    const [cuim, setCuim] = useState();
    const [isvisible, setVisible] = useState(false)
    const ref = useRef(null);

    const formSchema = Yup.object().shape({
        Email: Yup.string()
            .required("Campo Requerido")
            .email("Correo Electronico Invalido")
            .max(255, `Máximo 255 caracteres`),
        UserName: Yup.string()
            .min(5, `Mínimo 5 caracteres`)
            .max(25, `Máximo 25 caracteres`)
            .required("Campo Requerido"),
        Password: Yup.string()
            .required("Campo Requerido")
            .min(5, `Mínimo 5 caracteres`),
    });


    useEffect(() => {
        ref.current.focus()
    }, [])
    return (
        <>
            <Formik initialValues={
                {
                    Email: "",
                    UserName: "",
                    Password: "",

                }
            }
                validationSchema={formSchema}
                onSubmit={
                    (values) => {

                        props.valores(values);
                    }
                }>
                <Form >
                    <FormGroup ref={ref}>
                        <label htmlFor='UserName' > Usuario </label>
                        <Field className='form-control'

                            name='UserName'
                            placeholder=''
                            type='text' />
                        <ErrorMessage name='UserName'
                            component='div'
                            className='field-error text-danger' />
                    </FormGroup>
                    <FormGroup >
                        <label htmlFor='Email' > Correo Electronico: </label>
                        <Field className='form-control'
                            name='Email'
                            placeholder=''
                            type='email' />
                        <ErrorMessage name='Email'
                            component='div'
                            className='field-error text-danger' />
                    </FormGroup>
                    <FormGroup >
                        <label htmlFor='Password' > Contraseña: </label>
                        <Field className='form-control'
                            name='Password'
                            placeholder=''
                            type='password' />
                        <ErrorMessage name='Password'
                            component='div'
                            className='field-error text-danger' />
                    </FormGroup>

                    <Row>
                        <Col lg={12}
                            md={12} >
                            <div className="d-grid gap-2">
                                <Button
                                    type="submit"
                                    onClick={() => setVisible(false)}
                                    disabled={props.loading}
                                >

                                    {props.loading ? ("Esperar") : ("Ingresar")}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>

            </Formik>


        </>
    );
}