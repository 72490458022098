import { Table,Form } from "react-bootstrap";
import cflService from "../service/cfl-service";

const Tabla_Cursos = ({ curso,setFilaCurso,setVisible,setAlumnosxCruso,setLoadingAlumnosMatriculados }) => {
    const handleCheck =(data)=>{
        //console.log(data)
        setLoadingAlumnosMatriculados(true)
        cflService.buscarAlumnosMatriculadosCurso(data.idCurso)
        .then((resp)=>{
            //console.log(resp.data.data)
            setAlumnosxCruso(resp.data.data)
            setLoadingAlumnosMatriculados(false)
        })
        setFilaCurso(data)
        setVisible(true)
    }

    return (
        <Table striped bordered hover className="mt-3" responsive >
            <thead>
                <tr>
                    <th>NRO CURSO</th>
                    <th>Nombre</th>
                    <th>INICIO</th>
                    <th>FINAL</th>
                    <th>Acción</th>
                </tr>
            </thead>
            <tbody>

                {curso.map((data, i) => {
                    return (
                        <tr key={i}>
                            <td>{data.nroCurso}</td>
                            <td>{data.nombre}</td>
                            <td>{data.fechaInicio}</td>
                            <td>{data.fechaFinalizacion}</td>
                            <td>
                                <div className="center-accion">
                                <Form.Check
                                    onClick={()=>handleCheck(data)}
                                    name="group2"
                                    type="radio"
                                   
                                 />
                                 </div>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    )
}

export default Tabla_Cursos;