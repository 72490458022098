import { useState } from "react";
import { Col, Container, Form, Row, Button, Spinner } from "react-bootstrap";
import cflService from "../service/cfl-service";
import Tabla_Alumnos from "../components/Tabla_Alumnos";
import Tabla_Cursos from "../components/Tabla_Cursos";

import Espera from '../components/Espera'
import TablaAlumnosNotas from "../components/TablaAlumnosNotas";


const Nota = () => {
    const [criterioBusqueda, setCriterioDeBusqueda] = useState("");
    const [alumno, setAlumno] = useState([]);
    const [curso, setCurso] = useState([]);
    const [filaCurso, setFilaCurso] = useState();
    const [tableIsVisible, setTableIsVisible] = useState(false);
    const [alumnosxCurso, setAlumnosxCurso] = useState([]);
    const [loadingAlumnosMatriculados, setLoadingAlumnosMatriculados] = useState(false)
    const [loadindBuscarAlumno, setLoadingBuscarAlumno] = useState(false)
    const [loadindBuscarCurso, setLoadingBuscarCurso] = useState(false)
    const [loadingFichaCurso, setLoadingFichaCurso] = useState(false);

    const handleSelect = (e) => {
        console.log(e.target.value)
        setCriterioDeBusqueda(e.target.value)
    }

    const onInput = (e) => {

        if (criterioBusqueda === "dni") {
            let porDni = e.target.value
            if (porDni.length >= 3) {
                setLoadingBuscarAlumno(true)
                cflService.buscarPorDni(e.target.value)
                    .then((resp) => {
                        console.log(resp.data.data)
                        setAlumno(resp.data.data)
                        setLoadingBuscarAlumno(false)
                    })
                    .catch((error) => console.log(error))
            }

        }
        if (criterioBusqueda === "Nombre") {
            let nombre = e.target.value
            if (nombre.length >= 3) {
                setLoadingBuscarAlumno(true)
                cflService.buscarPorApellido(e.target.value)
                    .then((resp) => {
                        console.log(resp.data.data)
                        setAlumno(resp.data.data)
                        setLoadingBuscarAlumno(false)
                    })
                    .catch((error) => console.log(error))
            }

        }
    }
    const onInputCurso = (e) => {
        setLoadingBuscarCurso(true)
        if (criterioBusqueda === "nro_curso") {
            cflService.buscarCursoPorNroCurso(e.target.value)
                .then((resp) => {
                    console.log(resp.data.data)
                    setCurso(resp.data.data)
                    setLoadingBuscarCurso(false)
                })
                .catch((error) => console.log(error))
        }
        if (criterioBusqueda === "Nombre") {
            cflService.buscarCursoPorNombre(e.target.value)
                .then((resp) => {
                    console.log(resp.data.data)
                    setCurso(resp.data.data)
                    setLoadingBuscarCurso(false)
                })
                .catch((error) => console.log(error))
        }
    }

    const printNotas = async () => {
        setLoadingFichaCurso(true)

        alumnosxCurso.map((data) => {
            data.alumnosHasCursosByIdAlumnos.map(async (nota) => {
                console.log("NOTA", nota);
                await cflService.updateNotas(nota)
                    .then((res) => {
                        console.log("esta joya");
                    })
                    .catch((error) => {
                        console.log("salio mal");
                    });
            });

        })



        await cflService.actadeexamenPdf(filaCurso?.idCurso)





        setLoadingFichaCurso(false)
    }
    //console.log(filaCurso)
    return (
        <Container>

            {!tableIsVisible &&
                <><Row>
                    <h2>Buscar Cursos</h2>
                    <Col xs={12} md={6} className="mt-5">

                        <Form.Check
                            inline
                            label="Nombre"
                            name="radio-busqueda1"
                            value="Nombre"
                            type="radio"
                            onClick={handleSelect} />
                        <Form.Check
                            inline
                            label="Nro Curso"
                            name="radio-busqueda1"
                            value="nro_curso"
                            type="radio"
                            onClick={handleSelect} />


                    </Col>
                    <Col xs={12} md={6} className="mt-5">
                        <Row>
                            <Col md={12} xs={12}>
                                <Form.Group className="mb-6" controlId="buscar">

                                    <Form.Control type="text" placeholder={`Ingresa ${criterioBusqueda}`} onChange={onInputCurso} />
                                </Form.Group>
                            </Col>

                        </Row>

                    </Col>
                </Row>
                    {loadindBuscarCurso && <Espera />}
                    {!loadindBuscarCurso && <Tabla_Cursos
                        curso={curso}
                        setFilaCurso={setFilaCurso}
                        setVisible={setTableIsVisible}
                        setAlumnosxCruso={setAlumnosxCurso}
                        setLoadingAlumnosMatriculados={setLoadingAlumnosMatriculados}
                    />}
                </>}
            {tableIsVisible &&
                <>
                    <h2>Elegiste el siguiente curso :</h2>
                    <h3>{filaCurso.nombre}</h3>
                    {loadingAlumnosMatriculados && <Espera />}
                    {!loadingAlumnosMatriculados &&
                        <TablaAlumnosNotas
                            alumnoxCurso={alumnosxCurso}
                            setAlumnosxCurso={setAlumnosxCurso}
                            filacurso={filaCurso}

                        />}
                    
                    <Col xs={12} md={4} lg={4}>
                        <div className="d-grid gap-2">
                            <Button onClick={() => printNotas()} size="lg">
                                {loadingFichaCurso ? <Spinner animation="border" /> : "Acta de examen"}
                            </Button>
                        </div>
                    </Col>

                </>

            }
        </Container>
    )


}
export default Nota;