
import './App.css';
import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Navbar, Nav, NavDropdown, Button } from 'react-bootstrap';
import logo from "./assest/logo120x120.png"
import authService from './service/auth-service';
import Registro from './pages/Registro';
import AfterRegister from "./pages/AfterRegister";
import Login from './pages/Login';
import Matricular from './pages/Matricular';
import CrearAlumno from './pages/CrearAlumno';
import Alumnos from './pages/Alumnos2';
import Nota from './pages/Notas';
import Curso from './pages/Curso';

function App() {
  const [currentUser, setCurrentUser] = useState(undefined);
  const [showAdminBoard, setShowAdminBoard] = useState(false);
  const [showProfesorBoard, setShowProfesorBoard] = useState(false);
  const [showAlumnosBoard, setShowAlumnosBoard] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const user = authService.getCurrentUser();
    if (user) {
      setCurrentUser(user);
      setShowAdminBoard(user.roles?.includes("ROLE_ADMIN"));
      setShowProfesorBoard(user.roles?.includes("ROLE_PROFESOR"));
      setShowAlumnosBoard(user.roles?.includes("ROLE_ALUMNO"));
    }
  }, []);

  const logout = () => {
    authService.logout();
    navigate("/");
    window.location.reload();
  }




  return (
    <Container>
      <Navbar bg="light" expand="lg" variant='transparente' style={{ padding: "32px 0px 0px 0px" }}>

        <Navbar.Brand href="/entrada">
          <img
            href="/"
            alt="logo"
            src={logo}
            width="120px"
            height="120px"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">

            {showProfesorBoard && <Nav.Link href="/matricular"><Button variant='azul'>
              Ficha de curso
            </Button></Nav.Link>}

            {showProfesorBoard &&
              <Nav.Link href="/alumnos"><Button variant='azul'>Alumnos</Button></Nav.Link>
            }

            {showProfesorBoard &&
              <Nav.Link href="/notas"><Button variant='azul'>Acta de examen</Button></Nav.Link>
            }
            {showProfesorBoard &&
              <Nav.Link href="/curso"><Button variant='azul'>Curso</Button></Nav.Link>
            }
          </Nav >

          {showAlumnosBoard &&
            <Nav className="me-auto">

              <NavDropdown title="Panel" id="nav-dropdown">
                <NavDropdown.Item eventKey="4.1" href="/activar">Activar/Desactivar</NavDropdown.Item>
                <NavDropdown.Item eventKey="4.2" href="/blanqueo">Blanqueo de clave </NavDropdown.Item>
                <NavDropdown.Item eventKey="4.3" href="/cuim">Cuim</NavDropdown.Item>

                <NavDropdown.Item eventKey="4.4" href="/enviar">Enviar</NavDropdown.Item>
              </NavDropdown>
              <Nav.Item>
                <Nav.Link eventKey="1" href="/listado">
                  Listado
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="2" href="/administracion">
                  Administración
                </Nav.Link>
              </Nav.Item>
            </Nav>
          }
          {currentUser ? (
            <Nav>

              <Nav.Link onClick={logout}>
                <Button variant='azul'>
                  Cerrar Sesión
                </Button>
              </Nav.Link>
            </Nav>) :
            (
              <Nav>
                <Nav.Link href="/login">
                  <Button variant='azul'>
                    Ingresar
                  </Button>

                </Nav.Link>
                <Nav.Link href="/registro">
                  <Button variant='azul'>
                    Registrate
                  </Button>
                </Nav.Link>
              </Nav>
            )
          }

        </Navbar.Collapse>

      </Navbar>

      <Routes>
        <Route path="/login" element={<Login titulo={"Ingresar a CFL401SADA"} />} />
        <Route path="/registro" element={<Registro />} />
        <Route path="/afterregister" element={<AfterRegister />} />
        <Route path='matricular' element={<Matricular />} />
        <Route path='crearalumno' element={<CrearAlumno />} />
        <Route path='alumnos' element={<Alumnos />} />
        <Route path='notas' element={<Nota />} />
        
      </Routes>
    </Container>
  );
}

export default App;
