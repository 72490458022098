import { Table, Form } from "react-bootstrap";
import { RiDeleteBack2Line } from 'react-icons/ri';
import cflService from "../service/cfl-service";
import Swal from 'sweetalert2';



const TablaAlumnosNotas = ({ alumnoxCurso, setAlumnosxCurso, filacurso }) => {

    const deleteRow = (data) => {
        Swal.fire({
            title: '¿Esta seguro?',
            text: "Esta accion no tiene vuelta atrás",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, borrar!',
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                setAlumnosxCurso(alumnoxCurso.filter(item => item.idAlumnos != data.idAlumnos))
                Swal.fire(
                    'Borrado!',
                    `El alumno ${data.nombre}  ${data.apellido} ya no esta matriculado `,
                    'success'
                )
                cflService.deleteMatricular(filacurso.idCurso, data.idAlumnos)
                    .then((resp) => {
                        console.log("borrardo")
                    })
                    .catch((error) => console.log(error))

            }
        })


    }
    const handleTeoria = (e, data, cursoId) => {
        const { idAlumnos, alumnosHasCursosByIdAlumnos } = data;
        const newValue = e.target.value;
    
        // Validar que el valor esté dentro del rango de 0 a 100
        if (newValue >= 0 && newValue <= 100) {
            // Buscar el objeto correspondiente para el curso en el array de inscripciones
            const inscripcionEnCurso = alumnosHasCursosByIdAlumnos.find(
                (inscripcion) => inscripcion.cursoIdCurso === cursoId
            );
    
            if (inscripcionEnCurso) {
                // Actualizar el valor de teoría en la inscripción del curso
                inscripcionEnCurso.teoria =parseInt(newValue) ;
    
                // Actualizar el array de inscripciones en el objeto data
                const updatedAlumnosHasCursos = data.alumnosHasCursosByIdAlumnos.map((inscripcion) =>
                    inscripcion.cursoIdCurso === cursoId ? inscripcionEnCurso : inscripcion
                );
    
                // Actualizar el objeto data con el nuevo array de inscripciones
                const updatedAlumno = { ...data, alumnosHasCursosByIdAlumnos: updatedAlumnosHasCursos };
    
                // Actualizar el array de alumnos con el nuevo objeto data
                setAlumnosxCurso(alumnoxCurso.map((alumno) => (alumno.idAlumnos === idAlumnos ? updatedAlumno : alumno)));
            }
        }
    }
    
    const handlePractica = (e, data, cursoId) => {
        const { idAlumnos, alumnosHasCursosByIdAlumnos } = data;
        const newValue = e.target.value;
    
        // Validar que el valor esté dentro del rango de 0 a 100
        if (newValue >= 0 && newValue <= 100) {
            // Buscar el objeto correspondiente para el curso en el array de inscripciones
            const inscripcionEnCurso = alumnosHasCursosByIdAlumnos.find(
                (inscripcion) => inscripcion.cursoIdCurso === cursoId
            );
    
            if (inscripcionEnCurso) {
                // Actualizar el valor de teoría en la inscripción del curso
                inscripcionEnCurso.practica = parseInt(newValue);
    
                // Actualizar el array de inscripciones en el objeto data
                const updatedAlumnosHasCursos = data.alumnosHasCursosByIdAlumnos.map((inscripcion) =>
                    inscripcion.cursoIdCurso === cursoId ? inscripcionEnCurso : inscripcion
                );
    
                // Actualizar el objeto data con el nuevo array de inscripciones
                const updatedAlumno = { ...data, alumnosHasCursosByIdAlumnos: updatedAlumnosHasCursos };
    
                // Actualizar el array de alumnos con el nuevo objeto data
                setAlumnosxCurso(alumnoxCurso.map((alumno) => (alumno.idAlumnos === idAlumnos ? updatedAlumno : alumno)));
            }
        }
    }

    const handleNota = (e, data, cursoId) => {
        const { idAlumnos, alumnosHasCursosByIdAlumnos } = data;
        const newValue = e.target.value;
    
        // Validar que el valor esté dentro del rango de 0 a 100
        if (newValue >= 0 && newValue <= 100) {
            // Buscar el objeto correspondiente para el curso en el array de inscripciones
            const inscripcionEnCurso = alumnosHasCursosByIdAlumnos.find(
                (inscripcion) => inscripcion.cursoIdCurso === cursoId
            );
    
            if (inscripcionEnCurso) {
                // Actualizar el valor de teoría en la inscripción del curso
                inscripcionEnCurso.nota =parseInt(newValue) ;
    
                // Actualizar el array de inscripciones en el objeto data
                const updatedAlumnosHasCursos = data.alumnosHasCursosByIdAlumnos.map((inscripcion) =>
                    inscripcion.cursoIdCurso === cursoId ? inscripcionEnCurso : inscripcion
                );
    
                // Actualizar el objeto data con el nuevo array de inscripciones
                const updatedAlumno = { ...data, alumnosHasCursosByIdAlumnos: updatedAlumnosHasCursos };
    
                // Actualizar el array de alumnos con el nuevo objeto data
                setAlumnosxCurso(alumnoxCurso.map((alumno) => (alumno.idAlumnos === idAlumnos ? updatedAlumno : alumno)));
            }
        }
    }

    const handleEgresado = (e, data, cursoId) => {
        const { idAlumnos, alumnosHasCursosByIdAlumnos } = data;
        const newValue = e.target.value;
    
        // Validar que el valor esté dentro del rango de 0 a 100
       
            // Buscar el objeto correspondiente para el curso en el array de inscripciones
            const inscripcionEnCurso = alumnosHasCursosByIdAlumnos.find(
                (inscripcion) => inscripcion.cursoIdCurso === cursoId
            );
    
            if (inscripcionEnCurso) {
                // Actualizar el valor de teoría en la inscripción del curso
                inscripcionEnCurso.nroEgresado =parseInt(newValue) ;
    
                // Actualizar el array de inscripciones en el objeto data
                const updatedAlumnosHasCursos = data.alumnosHasCursosByIdAlumnos.map((inscripcion) =>
                    inscripcion.cursoIdCurso === cursoId ? inscripcionEnCurso : inscripcion
                );
    
                // Actualizar el objeto data con el nuevo array de inscripciones
                const updatedAlumno = { ...data, alumnosHasCursosByIdAlumnos: updatedAlumnosHasCursos };
    
                // Actualizar el array de alumnos con el nuevo objeto data
                setAlumnosxCurso(alumnoxCurso.map((alumno) => (alumno.idAlumnos === idAlumnos ? updatedAlumno : alumno)));
            }
        
    }
    
    console.log(alumnoxCurso)
    return (
        <Table striped bordered hover className="mt-3" responsive>
            <thead>
                <tr>
                    <th>Nombres</th>
                    <th>Apellidos</th>
                    <th>Teoría</th>
                    <th>Practica</th>
                    <th>Final</th>
                    <th>Nro. Egresado</th>
                </tr>
            </thead>
            <tbody>

                {alumnoxCurso.map((data, i) => {
                    
                    return (
                        
                        <tr key={i}>
                            <td>{data.nombre}</td>
                            <td>{data.apellido}</td>
                            <td>
                                <div className="center-accion">
                                    <Form.Group className="mb-3" >
                                       <Form.Control 
                                       type="number" 
                                       min="0" 
                                       max="100" 
                                       onChange={(e)=>{handleTeoria(e,data, filacurso.idCurso)}} 
                                       value={data.alumnosHasCursosByIdAlumnos.find((teoria)=>teoria.cursoIdCurso===filacurso.idCurso)?.teoria}
                                       />
                                    </Form.Group>
                                </div>
                            </td>
                            <td>
                                <div className="center-accion">
                                    <Form.Group className="mb-3" >
                                       <Form.Control 
                                       type="number" 
                                       min="0" 
                                       max="100" 
                                       onChange={(e)=>{handlePractica(e,data, filacurso.idCurso)}} 
                                       value={data.alumnosHasCursosByIdAlumnos.find((practica)=>practica.cursoIdCurso===filacurso.idCurso)?.practica}
                                       />
                                    </Form.Group>
                                </div>
                            </td>
                            <td>
                                <div className="center-accion">
                                    <Form.Group className="mb-3" >
                                       <Form.Control 
                                       type="number" 
                                       min="0" 
                                       max="100" 
                                       onChange={(e)=>{handleNota(e,data, filacurso.idCurso)}} 
                                       value={data.alumnosHasCursosByIdAlumnos.find((nota)=>nota.cursoIdCurso===filacurso.idCurso)?.nota}
                                       />
                                    </Form.Group>
                                </div>
                            </td>
                            <td>
                                <div className="center-accion">
                                    <Form.Group className="mb-3" >
                                       <Form.Control 
                                       type="number" 
                                       min="0" 
                                       max="100" 
                                       onChange={(e)=>{handleEgresado(e,data, filacurso.idCurso)}} 
                                       value={data.alumnosHasCursosByIdAlumnos.find((nroEgresado)=>nroEgresado.cursoIdCurso===filacurso.idCurso)?.nroEgresado}
                                       />
                                    </Form.Group>
                                </div>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    )
}
export default TablaAlumnosNotas;